<template>
  <div :class="['talent', { te: !talentData.hasApplied }]">
    <div class="talent-t">
      <div class="talent-tl">
        <div class="head">
          <div class="img">
            <img
              :src="
                talentData.avatar
                  ? talentData.avatar
                  : require('@/assets/img/default_avatar.png')
              "
              alt=""
            />
          </div>
          <div class="name">{{ talentData.userName }}</div>
        </div>
        <div class="label">
          <div
            class="item"
            v-for="(item, index) in talentData.talentTags"
            :key="index"
          >
            {{ item }}
          </div>
          <div class="nodata" v-if="!talentData.hasApplied">
            暂未申请“和”伙人，赶快申请吧~
          </div>
        </div>
      </div>
      <div class="talent-tr">
        <!-- <img
          v-if="talentData.talentRank"
          :src="returnModal(talentData.talentRank)"
          alt=""
        /> -->
      </div>
    </div>
    <div class="talent-b">
      <div
        v-for="(item, index) in talentData.talentTypes"
        :key="index"
        :class="['item', { teList: !item.remark }]"
        @click="toApplyTalent(item)"
      >
        <div :class="['item-t', item.backClass]">
          <div class="item-tl">
            <div class="title">{{ item.talentTypeName }}</div>
            <div
              :class="['status', item.class]"
              v-if="item.talentAuditStatus != 1"
            >
              {{ item.statusTxt }}
            </div>
            <div
              :class="['status', item.class]"
              v-if="item.talentAuditStatus == 1"
            >
              发布活动
            </div>
          </div>
          <div class="item-tr">
            <img :src="item.url" alt="" />
          </div>
        </div>
        <div class="item-b" v-if="item.remark">
          <div class="item-bc">{{ item.remark }}</div>
        </div>
      </div>
    </div>
    <div class="bottom" @click="toPartNer">和·伙人风采</div>
    <talentDialog
      v-if="!talentData.hasApplied"
      @closeTalent="closeTalent"
    ></talentDialog>
  </div>
</template>

<script>
import talentDialog from "./talentDialog";
import { talentUrl } from "./api.js";
import { talentLabel, modalList } from "./map.js";
import { getOption, handleImg } from "@/utils/utils.js";
import { toRegister, gloabalCount } from "@/utils/common.js";
var moment = require("moment");
export default {
  name: "talent",
  data() {
    return {
      form: {
        donatePic: "",
        donateContent: "",
      },
      talentData: {
        hasApplied: true,
      },
      talentLabel,
      modalList,
      isDialog: true,
    };
  },
  components: {
    talentDialog,
  },
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  async mounted() {
    if (await toRegister(this.$route.path, this.$route.query, "和伙人申请")) {
      this.getTalent();
    }
    gloabalCount("", 6, 1);
  },
  methods: {
    toPartNer() {
      this.$router.replace({
        name: "partnerStyleList",
      });
    },
    closeTalent() {
      this.talentData.hasApplied = true;
    },
    toApplyTalent(item) {
      if (item.talentAuditStatus == 1) {
        this.$router.push({
          name: "publishActivity",
        });
      } else {
        let query = { talentType: item.talentType };
        if (item.talentAuditId) {
          query = { talentType: item.talentType, id: item.talentAuditId };
        }
        this.$router.push({
          name: "applyTalent",
          query: query,
        });
      }
    },
    returnModal(status) {
      if (status) {
        return getOption(status, modalList, "key")["url"];
      } else {
        return modalList[0]["url"];
      }
    },
    toList() {
      this.$router.push({
        name: "pointsList",
      });
    },

    getTalent() {
      let params = {
        userId: this.userId,
        tenantId: this.tenantId,
      };
      this.$axios.get(`${talentUrl}`, { params }).then((res) => {
        if (res.code === 200) {
          if (res.data) {
            this.talentData = res.data;
            if (this.talentData.avatar) {
              this.talentData.avatar = handleImg(
                96,
                96,
                this.talentData.avatar
              );
            }
            if (
              this.talentData.talentTypes &&
              this.talentData.talentTypes.length != 0
            ) {
              this.talentData.talentTypes.forEach((element) => {
                if (element.talentAuditStatus == null) {
                  element.statusTxt = "立即申请";
                  element.class = "toApply";
                }
                if (element.talentAuditStatus == 0) {
                  element.statusTxt = "审核中";
                  element.class = "applyIng";
                }
                if (element.talentAuditStatus == 1) {
                  element.statusTxt = "申请成功";
                  element.class = "applyEd";
                }
                if (element.talentAuditStatus == 2) {
                  element.statusTxt = "申请失败";
                  element.class = "applyFail";
                }
                if (element.talentTypeName == "和伙人") {
                  element.backClass = "talent0";
                  element.url = require("./img/undraw_Working_re.png");
                }
                if (element.talentTypeName == "丰彩人") {
                  element.backClass = "talent1";
                  element.url = require("./img/undraw_art_muse.png");
                }
                if (element.talentTypeName == "众智人") {
                  element.backClass = "talent2";
                  element.url = require("./img/undraw_book_love.png");
                }
                if (element.talentTypeName == "共建和·伙人") {
                  element.backClass = "talent3";
                  element.url = require("./img/undraw_creative_team.png");
                }
              });
            } else {
              this.talentData.talentTypes = [];
            }
          }
        } else {
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.talent {
  height: 100vh;
  overflow: auto;
  background: #f5f5f5;
  padding-bottom: 99px;
  &.te {
    overflow: hidden;
  }
  .talent-t {
    height: 256px;
    background: url("./img/back.png") no-repeat;
    background-size: 100% 100%;
    padding: 8px 60px 0 32px;
    display: flex;
    justify-content: space-between;
    .talent-tl {
      padding-top: 32px;
      width: 460px;
      .head {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .img {
          width: 96px;
          height: 96px;
          border-radius: 50%;
          margin-right: 10px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
        .name {
          word-break: break-all;
          width: 280px;
          font-size: 32px;
          line-height: 34px;
          color: #fff;
        }
      }
      .label {
        .item {
          display: inline-block;
          padding: 0 10px;
          margin: 0 10px 10px 0;
          text-align: center;
          box-sizing: border-box;
          border-radius: 10px;
          border: 1px solid #fff;
          color: #fff;
          font-size: 24px;
        }
        .nodata {
          color: #fff;
          font-size: 24px;
        }
      }
    }
    .talent-tr {
      width: 134px;
      height: 232px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .talent-b {
    padding: 32px;
    .item {
      height: 400px;
      border-radius: 8px;
      margin-bottom: 24px;
      &.teList {
        height: 256px;
      }
      .item-t {
        display: flex;
        border-radius: 16px 16px 0 0;
        box-sizing: border-box;
        background: #90cbff;
        height: 256px;
        padding: 10px 18px 10px 48px;
        justify-content: space-between;
        .item-tl {
          padding-top: 50px;
          .title {
            font-size: 28px;
            line-height: 60px;
            color: #19125b;
            margin-bottom: 16px;
          }
          .status {
            width: 176px;
            height: 56px;
            text-align: center;
            background: rgba(79, 114, 248, 1);
            color: #fff;
            font-size: 28px;
            line-height: 56px;
            border-radius: 28px;
          }
          .applyEd {
            background: rgba(255, 255, 255, 0.8);
            color: #33378c;
          }
          .applyIng {
            background: none;
            border: 1px solid #fff;
            color: #fff;
          }
          .applyFail {
            background: none;
            border: 1px solid red;
            color: red;
          }
        }
        .item-tr {
          width: 400px;
          height: 220px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      &.teList {
        .item-t {
          border-radius: 16px;
        }
      }
      .talent1,
      .talent2,
      .talent3 {
        .item-tl {
          .title {
            color: #fff;
          }
        }
      }
      .talent1 {
        background: #ff8146;
      }
      .talent2 {
        background: #6dcbaf;
        .item-tr {
          width: 400px;
          text-align: center;
          img {
            width: 302px;
          }
        }
      }
      .talent3 {
        background: #33378c;
      }
      .item-b {
        height: 144px;
        background: #fff;
        box-sizing: border-box;
        border-radius: 0 0 16px 16px;
        box-sizing: border-box;
        padding: 20px 16px;
        font-size: 32px;
        color: #666;
        line-height: 36px;
        .item-bc {
          height: 100%;
          overflow: scroll;
        }
      }
    }
  }
  .bottom {
    height: 96px;
    background: #007eff;
    text-align: center;
    font-weight: bold;
    font-size: 32px;
    color: #ffffff;
    line-height: 96px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
</style>
<style lang="less">
.talent {
}
</style>
